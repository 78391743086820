.navigation{
    background-color: #f9fafe !important;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    box-shadow: 0px -10px 16px rgba(0,0,0,0.1);
    .MuiButtonBase-root {
        color: #333 !important;
    }
    .Mui-selected{ 
        color: dodgerblue !important;
    }
}