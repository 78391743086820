.matchCard {
  border-radius: 25px;
  box-shadow: 1px 3px 6px rgba(75, 79, 84, 0.4);
  background-color: white;
  padding: 20px 10px;
  color: #333;
  min-width: 0;
  .names {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    justify-content: center;
  }

  .name-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 16px;
    font-size: 18px;
    min-width: 0;
    div {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      min-width: 150px;
    }
  }
  .winners {
    color: #333;
    font-weight: bold;
    background: rgba(40, 167, 69, 0.1);
    padding: 2px 4px;
    border-radius: 10px;
  }
  .losers {
    color: #333;
    font-weight: bold;
    background: rgba(167, 40, 40, 0.1);
    padding: 2px 4px;
    border-radius: 10px;
  }

  .vs {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
  }

  .scores {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    font-size: 32px;
    font-weight: bold;
  }

  .date {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
}


@media only screen and (max-width: 380px) {
    .names {flex-direction: column;}
}