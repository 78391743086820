.row {
  max-width: 1320px;
  margin: 0 auto;
}

.container {
  padding-inline: 2rem;
}

.player-list {
  margin-top: 30px;
}

.row-titles {
  display: grid;
  grid-template-columns: 120px 1fr 1fr 1fr 1fr;
  text-align: center;
  gap: 16px;
  position: sticky;
  top: 0;
  background-color: #f9fafe;
  z-index: 10;
  padding: 16px 12px;
  margin-inline: -12px;
  h3 {
    font-size: 24px;
    margin: 0;
  }
}

.player-results {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 120px;
}

.player-card {
  display: grid;
  grid-template-columns: 120px 1fr 1fr 1fr 1fr;
  gap: 16px;
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  box-shadow: 1px 3px 6px rgba(75, 79, 84, 0.2);
  transform: skew(-30deg);

  .data {
    border-right: 1px solid #333;
    font-size: 20px;
    padding: 10px 0;
    transform: skew(30deg);
  }
  .data:last-of-type {
    border-right: none;
  }
}

.player-card:nth-of-type(1) {
  border: 2px solid #fee101;
  box-shadow: 1px 3px 6px rgba(254, 225, 1, 0.4);
  .data.rank {
    color: #d6af36;
  }
}

.player-card:nth-of-type(2) {
  border: 3px solid #d7d7d7;
  box-shadow: 1px 3px 6px rgba(215, 215, 215, 0.4);
  .data.rank {
    color: #a7a7ad;
  }
}

.player-card:nth-of-type(3) {
  border: 2px solid #a77044;
  box-shadow: 1px 3px 6px rgba(167, 112, 68, 0.4);
  .data.rank {
    color: #824a02;
  }
}

.data.rank {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.mobile {
  display: none;
}

.down {
  svg {
    color: #ff5050;
  }
}
.up {
  svg {
    color: #33cc33;
  }
}

.no-change-icon {
  width: 22px;
  color: grey;
}

.chevron-stack {
  display: flex;
  flex-direction: column;
  max-height: 22px;
  svg {
    width: 22px;
  }
}

.chevron-stack:has(.second-chev) {
  margin-top: -8px;
}

.second-chev {
  margin-top: -14px;
}

@media only screen and (max-width: 1200px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }

  .player-list {
    margin-top: 16px;
  }

  .player-results {
    padding-top: 0;
    padding-bottom: 90px;
  }
  .row-titles {
    grid-template-columns: 1fr 4fr 1fr 1fr 1fr;
    h3 {
      font-size: 16px;
    }
    .data.games {
      margin-right: -5px;
    }
    .data.player {
      text-align: left;
    }
  }
  .player-card {
    grid-template-columns: 1.5fr 4fr 1fr 1fr 1fr;
    .data {
      font-size: 16px;
    }

    .data.name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .data.rank {
      gap: 2px;
    }
  }
  .no-change-icon {
    width: 8px;
    color: grey;
  }

  .chevron-stack {
    display: flex;
    flex-direction: column;
    max-height: 22px;
    svg {
      width: 12px;
    }
    
    
  }
  .chevron-stack:has(.second-chev) {
    margin-top: 0px;
  }
  
  .second-chev {
    margin-top: -8px;
  }
} /* XL */

@media only screen and (max-width: 992px) {
} /* LG */

@media only screen and (max-width: 768px) {
} /* MD */

@media only screen and (max-width: 576px) {
} /* SM */
