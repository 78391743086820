@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

* {
  font-family: Figtree !important;
}

html {
  background-color: #f9fafe;
  color: #333;
}

h1 {
  font-size: 30px;
  display: flex;
  justify-content: center;
  padding-inline: 30px;
  text-align: center;
  text-wrap: balance;
}
@media only screen and (max-width: 1200px) {
  h1 {
    margin-top: 20px;
    font-size: 24px;
  }
}


.row {
  max-width: 1320px;
  margin: 0 auto;
}

.container {
  padding-inline: 2rem;
}